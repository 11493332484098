<template>
  <div class="w-100 d-flex flex-column align-self-baseline">
    <div class="content">
      <div class="logo order-1">
        <router-link
          :to="{ name: 'Home' }"
          aria-label="Torna alla homepage"
          :title="$t('common.goToHomepage')"
        >
          <img :src="logoSrc" alt="Logo Coop Trentino" />
        </router-link>
      </div>
      <div class="actions order-2 order-md-3 ml-auto ml-lg-0">
        <a
          :title="$t('common.goToClickandCoop')"
          :aria-label="$t('common.goToClickandCoop')"
          target="_blank"
          href="https://clickcoop.it"
          class="mx-md-3 px-3 py-1 click-and-coop d-flex aling-center text-decoration-none"
        >
          <v-icon
            fab
            class="text-decoration-none font-weight-bold"
            color="primary"
            >$shoppingCart</v-icon
          >
          <span
            class="my-auto text-decoration-none font-weight-medium default--text text-uppercase"
          >
            {{ $t("navbar.ecommerce") }}</span
          >
        </a>
        <v-btn
          :aria-label="$t('search.searchLabel')"
          @click="searchOverlay = !searchOverlay"
          icon
          class="elevation-0 
            "
        >
          <v-icon color="default">$search</v-icon>
        </v-btn>
        <template v-if="!isAuthenticated">
          <v-btn
            fab
            @click.stop="handleLogin()"
            class="elevation-0 d-inline"
            :aria-label="$t('login.title')"
          >
            <v-icon color="default">$profile</v-icon>
          </v-btn>
        </template>
        <template v-else>
          <div class="d-flex flex-row align-center text-caption">
            <!-- <v-btn
              :aria-label="$t('profile.goToProfileBtn')"
              @click="openDashboard"
              medium
              depressed
              tile
              :icon="!$vuetify.breakpoint.lgAndUp"
              :color="$vuetify.breakpoint.lgAndUp ? 'transparent' : 'default'"
              class="profile-btn"
              height="50"
            >
              <v-icon color="secondary" class="pr-2">$profile</v-icon>
              <span class="d-none d-lg-inline-block align-left welcome">
                Ciao <br />
                {{ cart.user.firstName }}
              </span>
            </v-btn> -->
            <v-btn
              @click="openDashboard"
              :aria-label="$t('profile.goToProfileBtn')"
              medium
              depressed
              tile
              :icon="!$vuetify.breakpoint.lgAndUp"
              :color="$vuetify.breakpoint.lgAndUp ? 'transparent' : 'default'"
              class="profile-btn"
              height="50"
            >
              <v-icon color="secondary" class="pr-2">$profile</v-icon>
              <span class="d-none d-lg-inline-block align-left welcome">
                Ciao <br />
                {{ cart.user.firstName }}
              </span>
            </v-btn>
          </div>
        </template>
        <v-menu offset-y transition="slide-y-transition" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              fab
              class="ml-3 mr-3 mr-md-0 elevation-0 d-inline"
              :aria-label="$t('language.title')"
            >
              <v-icon color="default">$globe</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in languages"
              :key="index"
              @click="setLanguage(item.abbr)"
            >
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="search order-3 order-md-2">
        <LinksMenu :items="primaryLinks" />
      </div>
    </div>
    <div class="w-100" v-if="searchOverlay">
      <SearchInput />
    </div>
  </div>
</template>
<style scoped lang="scss">
.v-overlay__scrim {
  height: 100vh !important;
}
.v-overlay__content {
  width: 100%;
}
.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 8px;
  flex-wrap: wrap;
  padding-left: 8px;
  padding-right: 8px;
}
.logo {
  display: flex;
  align-items: center;
  flex-grow: 1;
  max-width: fit-content;
}
.actions {
  display: flex;
  align-items: center;
  flex-grow: 0;
}
.click-and-coop {
  border-radius: 30px;
  border: 1px solid var(--v-primary-base);
}
.nav-links-wrapper ::v-deep .v-btn__content {
  opacity: 1 !important;
}

.search {
  width: 100%;
  flex-grow: 20;
}
.nav-links-wrapper {
  background-color: transparent !important;
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
  .search {
    width: calc(100% - 600px) !important;
    max-width: fit-content;
  }
}
@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .search {
    //width: 100% !important;
    // width: 960px !important;
  }
}
@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .content {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
}
.logo {
  display: flex;
  align-items: center;
  flex-grow: 1;
  max-width: fit-content;
}
.actions {
  display: flex;
  align-items: center;
  flex-grow: 0;
}
.click-and-coop {
  border-radius: 30px;
  border: 1px solid var(--v-primary-base);
}
.nav-links-wrapper ::v-deep .v-btn__content {
  opacity: 1 !important;
}

.search {
  width: 100%;
  flex-grow: 20;
}
.nav-links-wrapper {
  background-color: transparent !important;
}

.nav-buttons__router {
  border: 1px solid var(--v-primary-base);
}
::v-deep .v-toolbar__extension {
  display: block;
  padding: 0px;
  transition: height 0.2s ease;
  -webkit-transition: height 0.2s ease;
}
.header-banner {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    ::v-deep .v-slide-group__content {
      justify-content: center !important;
    }
  }
  .v-chip {
    color: $primary;
    font: normal normal 600 13px/28px $body-font-family;
    .v-avatar {
      height: 20px !important;
      width: 20px !important;
      min-width: 20px !important;
    }
  }
}
.nav-selectors {
  .info-text {
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.nav-buttons {
  .v-btn {
    padding: 0px 4px;
  }
  .service-icon {
    vertical-align: unset;
  }
  .points {
    img {
      width: 28px;
    }
  }
}
</style>
<script>
import SearchInput from "@/components/navigation/SearchInput.vue";
import LinksMenu from "@/components/navigation/LinksMenu.vue";
import cartInfo from "~/mixins/cartInfo";
import clickHandler from "~/mixins/clickHandler";
import login from "~/mixins/login";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "NavbarSite",
  mixins: [login, clickHandler, cartInfo],
  components: {
    SearchInput,
    LinksMenu
  },
  props: {
    primaryLinks: { type: Array, required: false }
  },
  data() {
    return {
      searchOverlay: false,
      languages: [
        { name: "Italiano", abbr: "it" },
        { name: "Deutsch", abbr: "de" }
      ]
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated",
      language: "app/getLanguage"
    }),
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft,
      storeDrawerRight: state => state.app.drawerRight,
      cart: ({ cart }) => cart.cart,
      orderToPay: state => state.custom.orderToPay
    }),
    logoSrc() {
      return `/logo/logo-${this.language || "it"}.svg`;
    }
    // extensionHeight() {
    //   if (this.$vuetify.breakpoint.smAndDown) {
    //     return 190;
    //   } else if (this.$vuetify.breakpoint.mdOnly) {
    //     return 190;
    //   } else {
    //     return 100;
    //   }
    // }
  },
  methods: {
    ...mapActions({
      logout: "cart/doLogout",
      setLanguage: "app/setLanguage",
      updateOrderToPay: "custom/updateOrderToPay"
    }),
    /** TODO: USE EMIT TO NAVBAR */
    async doLogout() {
      let res = await this.logout();
      if (res) {
        setTimeout(() => {
          this.$router.push({
            name: "Home",
            path: "/"
          });
        }, 200);
      }
    },
    // closeLogin() {
    //   this.$refs.loginMenuRef.isActive = false;
    // },
    // goBack() {
    //   this.$router.go(-1);
    // },
    openDashboard() {
      if (this.isAuthenticated) {
        this.$router.push("/profile/dashboard");
      }
    },
    // openCart() {
    //   if (this.editableCart) {
    //     if (this.isAuthenticated) {
    //       if (this.$vuetify.breakpoint.xs && this.editableCart) {
    //         this.$router.push({ name: "Cart" });
    //       } else {
    //         this.drawerRight = !this.drawerRight;
    //       }
    //     } else {
    //       this.doLogin("OpenCart");
    //     }
    //   } else {
    //     this.handleLogin();
    //   }
    // },
    async handleLogin() {
      if (await this.needLogin("navbar")) {
        // if (await this.needAddress()) {
        //   await this.needTimeslot();
        // }
      }
    },
    historyBack() {
      window.history.back();
    }
  },
  watch: {
    // searchOverlay(val) {
    //   val &&
    //     setTimeout(() => {
    //       this.searchOverlay = false;
    //     }, 2000);
    // },
    isAuthenticated(val, oldVal) {
      if (val != oldVal) {
        if (val == true) {
          this.updateOrderToPay();
        } else {
          this.updateOrderToPay([]);
        }
      }
    }
  }
};
</script>
