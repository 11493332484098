<template>
  <v-app-bar
    style="z-index: 5 !important;"
    app
    ref="navbar"
    class="appbar white"
    :class="mainTenantName == 'sait' ? 'pa-4' : ''"
    clipped-left
    :height="navbarHeight"
  >
    <!--  :height="isCordova ? navbarHeightExpanded : calculateNavbardHeight" -->
    <!-- :height="$vuetify.breakpoint.mdAndDown ? 150 : 100" -->

    <!-- <NavbarSiteSelection :applicationConfig="applicationConfig" /> -->
    <NavbarSite v-if="mainTenantName == 'sait'" :primaryLinks="primaryLinks" />
    <NavbarShop v-else />

    <!-- <template>
      <template v-if="applicationConfig && !alreadyShowedNewsBanner">
        <category-block
          v-if="applicationConfig && !alreadyShowedNewsBanner"
          :target="applicationConfig"
          position="header"
          class="category-block category-block-header w-100 h-100"
          :container="false"
        />
        <v-btn
          class="close-header-banner-btn close-news"
          icon
          color="default"
          @click="closeBannerNews()"
          :aria-label="$t('common.close')"
        >
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </template> -->
  </v-app-bar>
</template>
<script>
import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import clickHandler from "~/mixins/clickHandler";
import { mapActions, mapState, mapGetters } from "vuex";
//import NavbarSiteSelection from "./NavbarSiteSelection.vue";
import NavbarSite from "./NavbarSite.vue";
import NavbarShop from "./NavbarShop.vue";
export default {
  name: "Navbar",
  mixins: [login, clickHandler, cartInfo],
  components: {
    // SearchInput,
    // LinksMenu,
    //NavbarSiteSelection,
    NavbarShop,
    NavbarSite
  },
  props: {
    primaryLinks: { type: Array, required: false },
    navbarItems: { type: Object, default: () => {} },
    applicationConfig: { type: Object, required: false }
  },
  data() {
    return {
      // searchOverlay: false,
      bannerTopKey: 0,
      hidePendingOrders: false
      //  navbarHeightExpanded: 136,
      //  navbarHeightSmall: 80,
      //  navbarHeightMobileExpanded: 181,
      //  navbarHeightMobileSmall: 141,
      //calculateNavbardHeight: 0,
    };
  },
  methods: {
    ...mapActions({
      doLogout: "cart/doLogout",
      setLanguage: "app/setLanguage",
      updatedDrawerLeft: "app/updatedDrawerLeft",
      updatedDrawerRight: "app/updatedDrawerRight",
      updateOrderToPay: "custom/updateOrderToPay"
    }),
    ...mapGetters({
      //   site: "custom/getSite"
    }),
    // async setLanguage(lang) {
    //   console.log("lang", typeof lang);
    //   if (this.$i18n.locale !== lang) {
    //     this.$i18n.locale = lang;
    //     sessionStorage.setItem("X-Ebsn-Lang", lang);

    //     global.EventBus.$emit("restart");
    //   }
    // },
    closeBannerNews() {
      if (this.applicationConfig.categoryBlocks) {
        let headerNews = this.applicationConfig.categoryBlocks.find(
          block => block.templateBlock.codInt == "header"
        );
        if (headerNews) {
          let bannersTopShowingDate = {};
          bannersTopShowingDate[headerNews.categoryBlockId] = new Date(
            Date.now() + 3600 * 1000 * 24
          ).toISOString();
          window.sessionStorage["bannersTopShowingDate"] = JSON.stringify(
            bannersTopShowingDate
          );
        }
      }
      this.bannerTopKey++;
    }
    // closeLogin() {
    //   this.$refs.loginMenuRef.isActive = false;
    // },
    // goBack() {
    //   this.$router.go(-1);
    // },
    // openCart() {
    //   if (this.editableCart) {
    //     if (this.isAuthenticated) {
    //       if (this.$vuetify.breakpoint.xs && this.editableCart) {
    //         this.$router.push({ name: "Cart" });
    //       } else {
    //         this.drawerRight = !this.drawerRight;
    //       }
    //     } else {
    //       this.doLogin("OpenCart");
    //     }
    //   } else {
    //     this.handleLogin();
    //   }
    // },
    // openDashboard() {
    //   if (this.isAuthenticated) {
    //     this.$router.push("/profile/dashboard");
    //   }
    // },
    // async handleLogin() {
    //   if (await this.needLogin("navbar")) {
    //     // if (await this.needAddress()) {
    //     //   await this.needTimeslot();
    //     // }
    //   }
    // }
    // onScroll() {
    //   if (window.scrollY > 55 && !document.body.classList.contains("small")) {
    //     this.calculateNavbardHeight = this.$vuetify.breakpoint.smAndDown
    //       ? this.navbarHeightMobileSmall
    //       : this.navbarHeightSmall;
    //     document.body.classList.add("small");
    //   } else if (
    //     window.scrollY < 55 &&
    //     document.body.classList.contains("small")
    //   ) {
    //     this.calculateNavbardHeight = this.$vuetify.breakpoint.smAndDown
    //       ? this.navbarHeightMobileExpanded
    //       : this.navbarHeightExpanded;
    //     document.body.classList.remove("small");
    //   }
    // },
    // onResize() {
    //   let smAndDown = this.$vuetify.breakpoint.smAndDown;

    //   if (smAndDown && !document.body.classList.contains("sm-and-down")) {
    //     this.calculateNavbardHeight = document.body.classList.contains("small")
    //       ? this.navbarHeightMobileSmall
    //       : this.navbarHeightMobileExpanded;
    //     document.body.classList.add("sm-and-down");
    //   } else if (
    //     !smAndDown &&
    //     document.body.classList.contains("sm-and-down")
    //   ) {
    //     this.calculateNavbardHeight = document.body.classList.contains("small")
    //       ? this.navbarHeightSmall
    //       : this.navbarHeightExpanded;
    //     document.body.classList.remove("sm-and-down");
    //   }
    // }
  },
  computed: {
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft,
      storeDrawerRight: state => state.app.drawerRight,
      cart: ({ cart }) => cart.cart,
      orderToPay: state => state.custom.orderToPay,
      language: ({ app }) => app.language
    }),
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    logoSrc() {
      return `/logo/logo-${this.language || "it"}.svg`;
    },
    navbarHeight() {
      if (this.mainTenantName == "clickandcoop") {
        if (this.$vuetify.breakpoint.smAndDown) {
          return 150;
        } else if (this.$vuetify.breakpoint.mdAndDown) {
          return 150;
        } else {
          return 140;
        }
      } else {
        if (this.$vuetify.breakpoint.smAndDown) {
          return 200;
        } else if (this.$vuetify.breakpoint.smAndUp) {
          return 100;
        } else {
          100;
        }
      }
      return 100;
      //return 200; mobile
      // return 150;  tablet
      //  return 150; desktop 100
      // if (this.site == "site") {
      //   return this.$vuetify.breakpoint.mdAndDown ? 150 : 100;
      // } else{
      //   return 100;
      //   // return this.navbarHeightExpanded;
      // } else {
      //   return 150;
      //   // return this.calculateNavbarHeight;
      // }
    },
    enableRegistration() {
      return global.config.registration;
    },
    totalItems() {
      return this.cart.totalItems;
    },
    totalItemQuantity() {
      return this.cart.cartItems?.reduce((acc, item) => {
        return acc + item.quantity + item.weight;
      }, 0);
    },
    editableCart() {
      return !(this.$route.name == "Payment");
    },
    // showOverlay() {
    //   return this.$store.state.app.pendingRequests.count > 0;
    // },
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    },
    drawerRight: {
      get() {
        return this.storeDrawerRight;
      },
      set(value) {
        this.updatedDrawerRight(value);
      }
    }
    // alreadyShowedNewsBanner() {
    //   this.bannerTopKey;
    //   if (this.applicationConfig.categoryBlocks) {
    //     let headerNews = this.applicationConfig.categoryBlocks.find(
    //       block => block.templateBlock.codInt == "header"
    //     );
    //     if (headerNews) {
    //       let bannersTopShowingDate = window.sessionStorage[
    //         "bannersTopShowingDate"
    //       ]
    //         ? JSON.parse(window.sessionStorage["bannersTopShowingDate"])
    //         : {};
    //       let catBlockId = headerNews.categoryBlockId;

    //       let nextOpeningDate = bannersTopShowingDate[catBlockId];
    //       let today = new Date();
    //       if (
    //         Object.prototype.hasOwnProperty.call(
    //           bannersTopShowingDate,
    //           catBlockId
    //         ) &&
    //         Date.parse(nextOpeningDate) > Date.parse(today)
    //       ) {
    //         // there is banner but close already clicked
    //         return true;
    //       }

    //       // show banner
    //       return false;
    //     } else {
    //       // there is no proposal -> do not show news banner
    //       return true;
    //     }
    //   }
    //   // there is no banner to show
    //   return true;
    // }
  },
  watch: {
    // showOverlay(val) {
    //   val &&
    //     setTimeout(() => {
    //       this.showOverlay = false;
    //     }, 2000);
    // },

    isAuthenticated(val, oldVal) {
      if (val != oldVal) {
        if (val == true) {
          this.updateOrderToPay();
        } else {
          this.updateOrderToPay([]);
        }
      }
    }
  }
  // mounted() {
  //   this.onResize();
  //   this.calculateNavbardHeight = this.$vuetify.breakpoint.smAndDown
  //     ? this.navbarHeightMobileExpanded
  //     : this.navbarHeightExpanded;
  // }
};
</script>

<style scoped lang="scss">
.test {
  background-color: var(--v-primary-base);
}
.v-overlay__scrim {
  height: 100vh !important;
}
.v-overlay__content {
  width: 100%;
}
.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 8px;
  flex-wrap: wrap;
  padding-left: 8px;
  padding-right: 8px;
}
.logo {
  display: flex;
  align-items: center;
  flex-grow: 1;
  max-width: fit-content;
}
.actions {
  display: flex;
  align-items: center;
  flex-grow: 0;
}
.click-and-coop {
  border-radius: 30px;
  border: 1px solid var(--v-primary-base);
}
.nav-links-wrapper ::v-deep .v-btn__content {
  opacity: 1 !important;
}

.search {
  width: 100%;
  flex-grow: 20;
}
.nav-links-wrapper {
  background-color: transparent !important;
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
  .search {
    width: calc(100% - 450px);
    max-width: fit-content;
  }
}
@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .search {
    width: 600px !important;
    max-width: 600px;
  }
}
@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .content {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
}
.logo {
  display: flex;
  align-items: center;
  flex-grow: 1;
  max-width: fit-content;
}
.actions {
  display: flex;
  align-items: center;
  flex-grow: 0;
}
.click-and-coop {
  border-radius: 30px;
  border: 1px solid var(--v-primary-base);
}
.nav-links-wrapper ::v-deep .v-btn__content {
  opacity: 1 !important;
}

.search {
  width: 100%;
  flex-grow: 20;
}
.nav-links-wrapper {
  background-color: transparent !important;
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
  .search {
    width: calc(100% - 450px);
    max-width: fit-content;
  }
}

.nav-buttons__router {
  border: 1px solid var(--v-primary-base);
}
::v-deep .v-toolbar__extension {
  display: block;
  padding: 0px;
  transition: height 0.2s ease;
  -webkit-transition: height 0.2s ease;
}
.header-banner {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    ::v-deep .v-slide-group__content {
      justify-content: center !important;
    }
  }
  .v-chip {
    color: $primary;
    font: normal normal 600 13px/28px $body-font-family;
    .v-avatar {
      height: 20px !important;
      width: 20px !important;
      min-width: 20px !important;
    }
  }
}
.nav-selectors {
  .info-text {
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.nav-buttons {
  .v-btn {
    padding: 0px 4px;
  }
  .service-icon {
    vertical-align: unset;
  }
  .points {
    img {
      width: 28px;
    }
  }
}
</style>
