<template>
  <v-container fluid class="pa-3  pa-lg-5 navbar-line navbar-shop">
    <div class="navbar-row d-flex align-center">
      <!-- menu col -->
      <div
        class="menu-column menu-btn order-0 d-flex justify-start align-center column pl-4 pl-md-0 "
      >
        <!-- logo col -->
        <div class="order-2 order-md-1 logo-column column">
          <router-link :to="{ name: 'Home' }" class="d-flex align-center">
            <!-- logo desktop -->
            <img
              :width="$vuetify.breakpoint.smAndDown ? '70' : ''"
              class="px-0 d-none d-sm-block logo-desktop"
              :src="logoSrc"
              alt="Logo click-and-coop"
            />
          </router-link>
        </div>
        <div
          class="px-md-4 order-1 order-md-2 d-flex justify-center align-center"
        >
          <v-btn
            x-large
            aria-label="Apri menu categorie"
            icon
            class="open-menu-btn"
            @click.prevent.stop="drawerLeft = !drawerLeft"
          >
            <v-icon color="default">$menu</v-icon>
          </v-btn>
          <small
            class="d-none d-md-block default--text text-uppercase font-weight-bold"
          >
            {{ $t("navbar.products") }}
          </small>
        </div>
      </div>

      <div
        class="search-input-column order-4 order-md-2 column d-flex px-4 px-sm-0 px-md-4 px-lg-8 mt-5 mt-md-0"
      >
        <SearchInput />
      </div>
      <!-- buttons col -->
      <div
        class="ml-auto buttons-column column order-2 order-md-3 d-flex justify-end align-center"
      >
        <template v-if="!isAuthenticated" align-center justify-end>
          <div class="d-flex align-center">
            <v-btn icon aria-label="Vai al profilo" @click.stop="handleLogin()">
              <v-icon
                width="24"
                height="24"
                class="font-weight-bold"
                color="default"
                >$profile</v-icon
              >
            </v-btn>
            <v-btn
              aria-label="Apri il carrello"
              icon
              @click.stop="handleLogin()"
            >
              <v-icon
                width="24"
                height="24"
                color="default"
                class="font-weight-bold"
                >$shoppingCart</v-icon
              >
            </v-btn>
          </div>
        </template>

        <template v-else>
          <div class="delivery-service-selector d-flex align-center">
            <!-- ADDRESS AND TIME SELECTOR -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  v-bind="attrs"
                  v-on="on"
                  @click="openAddressSelector"
                  class="d-none d-md-inline service-button"
                  height="50"
                  alt="Selezione servizio"
                  aria-label="Selezione servizio"
                >
                  <v-icon x-large class="service-icon">
                    {{
                      "$" +
                        $t(`navbar.service.${getDeliveryServiceId}.fontIcon`)
                    }}
                  </v-icon>
                </v-btn>
              </template>
              <span class="delivery-service-tooltip-content">
                <strong
                  >{{ shippingAddress }}<br />
                  {{ timeslot }}</strong
                >
              </span>
            </v-tooltip>

            <div class="nav-selectors d-none d-lg-inline">
              <v-btn
                x-small
                text
                class="d-flex justify-space-between"
                @click="openAddressSelector"
              >
                <span class="info-text font-weight-bold">{{
                  shippingAddress
                }}</span
                ><v-icon
                  v-if="editableAddress"
                  color="default"
                  x-small
                  class="ml-1"
                  >$edit</v-icon
                >
              </v-btn>
              <v-btn
                x-small
                text
                class="d-flex justify-space-between"
                @click="openTimeslotSelector"
              >
                <span class="info-text font-weight-regular text-lowercase">
                  {{ timeslot }} </span
                ><v-icon
                  v-if="editableTimeslot"
                  color="default"
                  x-small
                  class="ml-1"
                  >$edit</v-icon
                >
              </v-btn>
            </div>
          </div>
          <v-btn icon :to="{ name: 'Lists' }">
            <v-icon color="default">$heart</v-icon>
          </v-btn>
          <!-- <gift-menu-button /> -->

          <div class="d-flex flex-row align-center mr-2 text-caption">
            <v-btn
              @click="openDashboard"
              aria-label="Vai al profilo"
              tile
              depressed
              color="white"
              class="profile-btn"
            >
              <!-- <v-icon color="default" class="font-weight-bold"
                  >$profile</v-icon
                > -->
              <div class="username d-block text-caption ml-2">
                <!-- <span>{{ $t("navbar.userMessage") }}</span> <br /> -->
                <strong class="default--text">{{ cart.user.firstName }}</strong>
              </div>
            </v-btn>
            <!-- <v-menu bottom offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="openDashboard"
                  aria-label="Vai al profilo"
                  v-bind="attrs"
                  v-on.stop="on"
                  tile
                  depressed
                  color="white"
                  class="profile-btn"
                >
                  <!- - <v-icon color="default" class="font-weight-bold"
                  >$profile</v-icon
                > - ->
                  <div class="username d-block text-caption ml-2">
                    <!-  - <span>{{ $t("navbar.userMessage") }}</span> <br /> - ->
                    <strong class="default--text">{{
                      cart.user.firstName
                    }}</strong>
                  </div>
                </v-btn>
              </template>
              <UserMenu />
            </v-menu> -->

            <transition
              name="custom-classes"
              enter-active-class="animate__animated animate__swing"
            >
              <v-badge
                :content="totalItems"
                :value="totalItems > 0"
                :key="totalItemQuantity"
                color="primary"
                overlap
                :offset-x="$vuetify.breakpoint.mdAndDown ? 22 : 91"
                offset-y="25"
                class="d-flex flex-row align-center font-weight-bold cart-badge"
              >
                <v-btn
                  aria-label="Apri carrello"
                  medium
                  :icon="!$vuetify.breakpoint.lgAndUp"
                  depressed
                  tile
                  :color="
                    $vuetify.breakpoint.lgAndUp ? 'transparent' : 'default'
                  "
                  @click.stop="openCart()"
                  class="cart-btn"
                  height="50"
                  :width="$vuetify.breakpoint.mdAndDown ? 50 : 110"
                >
                  <v-icon color="default">$cart</v-icon>
                  <div class="d-flex flex-column">
                    <!-- <small class="d-inline-block text-left  text-capitalize"
                      >carrello</small
                    > -->
                    <div
                      class="d-none d-lg-flex flex-column align-start align-left cart-total"
                    >
                      <small>{{ $t("bottom.cart") }}</small>
                      <span class="font-weight-bold default--text ml-1"
                        >{{ $n(cart.totalPrice, "currency") }}
                      </span>
                    </div>
                  </div>
                </v-btn>
              </v-badge>
            </transition>
          </div>
        </template>
        <v-menu offset-y transition="slide-y-transition" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              class="mr-3 mr-md-0 elevation-0 d-inline"
              :aria-label="$t('language.title')"
            >
              <v-icon class="font-weight-bold" color="default">$globe</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in languages"
              :key="index"
              @click="setLanguage(item.abbr)"
            >
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </v-container>
</template>
<style lang="scss">
.navbar-shop {
  .search-input-column {
    flex-grow: 2;
    .search-input-container {
      width: 100%;
      max-width: 800px;
    }
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .navbar-row {
      flex-wrap: wrap;
    }
    .buttons-column,
    .menu-column {
      flex-basis: 25%;
    }
    .logo-column {
      flex-basis: 50%;
    }
    .search-input-column {
      flex-basis: 100%;
      .search-input-container {
        flex-basis: 100%;
      }
    }
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .buttons-column {
      flex-basis: 30%;
      .profile-btn.v-btn.v-size--default {
        padding: 0px 8px;
      }
    }
    .menu-column {
      flex-basis: 20%;
    }
    .logo-column {
      flex-basis: 48%;
    }
  }
  @media (max-width: 374px) {
    .buttons-column {
      flex-basis: 34%;
    }
    .menu-column {
      flex-basis: 20%;
    }
    .logo-column {
      flex-basis: 44%;
      .logo-mobile {
        height: 30px !important;
      }
    }
  }
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    .search-input-column {
      justify-content: center;
    }
  }
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    .search-input-column .search-input-container {
      flex-basis: 90%;
    }
  }
  .shopping-cart-icon.v-badge__badge {
    color: var(--v-default-base) !important;
    padding: 4px 4px;
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      display: none;
    }
  }
}
.shake-enter-active {
  animation: shakeCart 0.4s ease-in-out forwards;
}
@keyframes shakeCart {
  25% {
    transform: translateX(6px);
  }
  50% {
    transform: translateX(-4px);
  }
  75% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
<script>
import SearchInput from "@/components/navigation/SearchInput.vue";
//import UserMenu from "@/components/navigation/UserMenu.vue";
import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import clickHandler from "~/mixins/clickHandler";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "NavbarShop",
  mixins: [login, clickHandler, cartInfo],
  components: {
    SearchInput
  },
  props: {
    applicationConfig: { type: Object, required: false }
  },
  data() {
    return {
      languages: [
        { name: "Italiano", abbr: "it" },
        { name: "Deutsch", abbr: "de" }
      ]
    };
  },
  computed: {
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft,
      storeDrawerRight: state => state.app.drawerRight,
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    logoSrc() {
      return `/logo/click-and-coop-${this.language || "it"}.svg`;
    },
    enableRegistration() {
      return global.config.registration;
    },
    totalItems() {
      return this.cart.totalItems;
    },
    totalItemQuantity() {
      return this.cart.cartItems?.reduce((acc, item) => {
        return acc + item.quantity + item.weight;
      }, 0);
    },
    editableCart() {
      return !(this.$route.name == "Payment");
    },
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    },
    drawerRight: {
      get() {
        return this.storeDrawerRight;
      },
      set(value) {
        this.updatedDrawerRight(value);
      }
    }
  },
  methods: {
    ...mapActions({
      logout: "cart/doLogout",
      updatedDrawerLeft: "app/updatedDrawerLeft",
      setLanguage: "app/setLanguage",
      // updatedDrawerLeftSite: "app/updatedDrawerLeftSite",
      updatedDrawerRight: "app/updatedDrawerRight",
      updatedDrawerLinks: "app/updatedDrawerLinks"
    }),
    /** TODO: USE EMIT TO NAVBAR */
    openDashboard() {
      if (this.isAuthenticated) {
        this.$router.push("/profile/dashboard");
      }
    },
    async doLogout() {
      let res = await this.logout();
      if (res) {
        setTimeout(() => {
          this.$router.push({
            name: "Home",
            path: "/"
          });
        }, 200);
      }
    },
    // closeLogin() {
    //   this.$refs.loginMenuRef.isActive = false;
    // },
    // goBack() {
    //   this.$router.go(-1);
    // },
    openCart() {
      if (this.editableCart) {
        if (this.isAuthenticated) {
          if (this.$vuetify.breakpoint.xs && this.editableCart) {
            this.$router.push({ name: "Cart" });
          } else {
            this.drawerRight = !this.drawerRight;
          }
        } else {
          this.doLogin("OpenCart");
        }
      } else {
        this.handleLogin();
      }
    },
    async handleLogin() {
      if (await this.needLogin("navbar")) {
        if (await this.needAddress()) {
          await this.needTimeslot();
        }
      }
    },
    historyBack() {
      window.history.back();
    }
  }
};
</script>
