<template>
  <v-sheet>
    <v-slide-group
      class="d-flex align-center"
      multiple
      show-arrows
      aria-label="Main navigation"
      ref="slideGroup"
    >
      <template v-slot:prev>
        <v-btn small icon aria-label="Scroll left">
          <v-icon>$chevronLeft</v-icon>
        </v-btn>
      </template>
      <v-slide-item
        class="nav-links-wrapper"
        v-for="(item, idx) in items"
        :key="idx"
        v-slot="{ active, toggle }"
      >
        <v-btn
          text
          class="mx-0"
          :input-value="active"
          active-class="white--text"
          depressed
          rounded
          @click="toggle"
        >
          <a
            :title="item.name"
            v-if="item.templateNavItems?.length === 0"
            class="pa-3 default--text text-decoration-none"
            :class="item.classes"
            :href="item.itemUrl"
            @click.prevent="clicked"
          >
            <span class="font-weight-medium text-uppercase">{{
              item.name
            }}</span>
          </a>
          <v-menu
            v-else
            bottom
            offset-y
            x
            content-class="py-5 px-4 children-links-menu rounded-md"
          >
            <template v-slot:activator="{ on, attrs }">
              <a
                :aria-label="item.name"
                :title="item.name"
                :id="`header-menu-${item.templateNavItemId}`"
                aria-haspopup="false"
                aria-expanded="false"
                v-bind="attrs"
                v-on.stop="on"
                class="default--text pa-3 d-flex align-center"
                :class="item.classes"
                @click.prevent="clicked"
              >
                <span class="mr-1 font-weight-medium text-uppercase">{{
                  item.name
                }}</span>
                <v-icon
                  color="default"
                  v-if="item.templateNavItems?.length > 0"
                  small
                >
                  $chevronDown
                </v-icon>
              </a>
            </template>
            <div>
              <div
                v-for="(child, idx2) in item.templateNavItems"
                :key="idx2"
                class="nav-link-wrapper"
              >
                <a
                  :aria-label="child.name"
                  :title="child.name"
                  class="pr-10 pa-2 text-body-2 default--text d-block w-100 nav-link"
                  :class="child.classes"
                  :href="child.itemUrl"
                  @click.prevent="clicked"
                >
                  {{ child.name }}
                </a>
                <div class="d-flex flex-column">
                  <a
                    :aria-label="child2.name"
                    :title="child2.name"
                    v-for="(child2, idx3) in child.templateNavItems"
                    :key="idx3"
                    :href="child2.itemUrl || '#'"
                    class="text-body-2 default--text my-1 d-block w-100 nav-link pa-2"
                    :class="child2.classes"
                    :target="child2.target || '_self'"
                    @click.prevent="clicked"
                  >
                    <span :class="child2.classes">{{ child2.name }}</span>
                  </a>
                </div>
              </div>
            </div>
          </v-menu>
        </v-btn>
      </v-slide-item>
      <template v-slot:next>
        <v-btn small icon aria-label="Scroll right">
          <v-icon>$chevronRight</v-icon>
        </v-btn>
      </template>
    </v-slide-group>
  </v-sheet>

  <!-- eslint-enable vue/no-use-v-if-with-v-for -->
</template>
<style lang="scss">
.v-slide-group__prev--disabled,
.v-slide-group__next--disabled {
  display: none !important;
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";

import { mapGetters } from "vuex";

export default {
  name: "LinksMenu",
  mixins: [clickHandler],
  props: {
    items: { type: Array, required: true }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    })
  }
};
</script>

<style scoped lang="scss">
.navbar-menu {
  height: $navbar-menu-height;
  .drawer-button {
    height: $navbar-menu-height;
    min-width: $drawer-btn-width;
  }
  .items-wrapper {
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    overflow-y: hidden;
    white-space: nowrap;
    height: 35px;
  }

  a {
    color: var(--v-default-base) !important;
    line-height: 2;
  }
}
.children-links-menu {
  background-color: white;
  margin-top: 5px !important;
  max-width: 100%;
  min-width: 150px !important;
  // border-radius: 12px 12px 12px 0px !important;
  z-index: 10 !important;
  .v-menu__content {
    box-shadow: 0px 3px 6px #00000029;
  }
  a {
    text-decoration: none;
  }
  .v-card {
    &.yellow-gradient {
      background-color: #dda801;
      background: linear-gradient(
        0deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(191, 145, 6, 1) 28%,
        rgba(205, 156, 4, 1) 39%,
        #dda801 44%
      );
    }
    &.transparent-gradient {
      .v-card__title,
      .v-card__subtitle {
        z-index: 2;
      }
      .gradient-layer {
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, black, transparent);
      }
    }
  }
  .nav-link-wrapper:not(:last-child) {
    border-bottom: 1px solid var(--v-grey-lighten3);
  }
  .nav-link:hover {
    background-color: var(--v-grey-lighten1);
  }
}
</style>
